<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6 lg:col-5 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div>
                                        <div class="text-2xl text-primary font-bold">Add New Counter</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container-fluid">
                <div class="grid ">
                    <div class="md:col-5 sm:col-12 comp-grid" >
                        <div class=" mt-4">
                            <div >
                                <form ref="observer" tag="form" @submit.prevent="submitForm()">
                                    <div class="grid">
                                        <div class="col-4">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    User *
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrluser" v-model.trim="formData.user"  label="User" type="text" placeholder="Enter User"      
                                                    class=" w-full" :class="getErrorClass('user')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('user')" class="p-error">{{ getFieldError('user') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Ref *
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrlref" v-model.trim="formData.ref"  label="Ref" type="text" placeholder="Enter Ref"      
                                                    class=" w-full" :class="getErrorClass('ref')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('ref')" class="p-error">{{ getFieldError('ref') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Logdate *
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrllogdate" v-model.trim="formData.logdate"  label="Logdate" type="text" placeholder="Enter Logdate"    readonly  
                                                    class=" w-full" :class="getErrorClass('logdate')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('logdate')" class="p-error">{{ getFieldError('logdate') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="showSubmitButton" class="text-center my-3">
                                        <Button class="p-button-primary" type="submit" label="Submit" icon="pi pi-send" :loading="saving" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="md:col-5 sm:col-12 comp-grid" >
                        <div class="">
                            <div class="reset-grid">
                                <WaybillsAddPage is-sub-page ref="waybillsAddPage"  :show-header="true"  v-if="pageReady">
                                </WaybillsAddPage>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="page-section mb-3" >
            <div class="container-fluid">
                <div class="grid ">
                    <div class="col comp-grid" >
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>
	import {  computed,  reactive, toRefs, onMounted } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { required } from '@/services/validators';
	import { ApiService } from '@/services/api';
	import { utils } from '@/utils';
	import { useApp } from '@/composables/app.js';
	import { useAuth } from '@/composables/auth';
	import { useAddPage } from '@/composables/addpage.js';
	import WaybillsAddPage from "../waybills/add.vue";
	const props = defineProps({
		pageName : {
			type : String,
			default : 'counter',
		},
		routeName : {
			type : String,
			default : 'counteradd',
		},
		apiPath : {
			type : String,
			default : 'counter/add',
		},
		submitButtonLabel: {
			type: String,
			default: "Submit",
		},
		msgAfterSave: {
			type: String,
			default: "Record added successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type : Boolean,
			default : true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
		pageData: { // use to set formData default values from another page
			type: Object,
			default: () => {} 
		},
	});
	const app = useApp();
	const auth = useAuth();
	const formDefaultValues = {
		user: auth.userName, 
		ref: "", 
		logdate: utils.dateTimeNow(), 
	}
	const formData = reactive({ ...formDefaultValues });
	// perform custom validation before submit form
	// set custom form fields
	// return false if validation fails
	function beforeSubmit(){
		return true;
	}
	// after form submited to api
	// reset form data.
	// redirect to another page
	function onFormSubmited(response) {
		app.flashMsg(props.msgAfterSave);
		Object.assign(formData, formDefaultValues); //reset form data
		if(props.redirect) app.navigateTo(`/counter`);
	}
	//form validation rules
	const rules = computed(() => {
		return {
			user: { required },
			ref: { required },
			logdate: { required }
		}
	});
	const v$ = useVuelidate(rules, formData); // form validation
	const page = useAddPage({ props, formData, v$, onFormSubmited, beforeSubmit });
	//page state
	const {
		submitted, // form submitted state - Boolean
		saving, // form api submiting state - Boolean
		pageReady, // if api data loaded successfully
	} = toRefs(page.state);
	//page methods
	const {
		submitForm, //submit form data to api
		getErrorClass, // return error class if field is invalid- args(fieldname)
		getFieldError, //  get validation error message - args(fieldname)
		isFieldValid, // check if field is validated - args(fieldname)
		 // map api datasource  to Select options label-value
	} = page.methods;
	onMounted(()=>{
		const pageTitle = "Add New Counter";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
	});
	async function getData() {
  try{
    //fetch data from the api
    let response = await ApiService.get("counter/getcounterid");
    let idd = parseInt(response.data[0].recid);
    let one = parseInt(1);
    let total = idd+one;
    console.log(total);
    formData.ref =utils.dateNow().slice(-5).substring(0,2) + utils.dateNow().substring(0,4).slice(-2) +"-"+ total;
  }
  catch(e){
    console.error(e); 
  }
};
onMounted(()=>{
        getData();
    });
	// expose page object for other components access
	defineExpose({
		page
	});
</script>
<style scoped>
</style>
